import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

interface INylasService {
    $axios: NuxtAxiosInstance
    getNylasAuthToken: () => Promise<any>
    setNylasTokenInEmissary: (code: string) => Promise<any>
    getCalendars: () => Promise<any>
    generateMeetingLink: (meetingData: MeetingLinkCreationData) => Promise<any>
    getConfigId: (bookingId: string) => Promise<any>
}

type MeetingLinkCreationData = {
    calendar_account_id: string,
    sms_thread_id: string,
    event_duration: '30' | '60'
}
export default class NylasService implements INylasService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  public getNylasAuthToken () {
    return this.$axios.$get('/api/integrations/nylas-redirect').then(({ auth_url }) => auth_url)
  }

  public getCalendarSettings (calendarId: string) {
    return this.$axios.$get(`/api/integrations/nylas-get-calendar-settings?calendar_id=${calendarId}`).then(data => ({ ...data, openHours: data.default_open_hours }))
  }

  public setCalendarSettings (calendar_id: string, selectedConfiguration: any) {
    return this.$axios.$post('/api/integrations/nylas-set-calendar-settings', { calendar_id, ...selectedConfiguration })
  }

  public getConfigId (bookingId: string) {
    return this.$axios.$get(`/api/integrations/nylas-get-configuration-id?booking_id=${bookingId}`)
  }

  public getCalendars () {
    return this.$axios.$get('/api/integrations/nylas-calendars')
  }

  public deleteCalendar (calendar_id: string) {
    return this.$axios.$delete('/api/integrations/nylas-delete-calendar', { data: { calendar_id } })
  }

  public generateMeetingLink (meetingData: MeetingLinkCreationData) {
    return this.$axios.$post('/api/integrations/nylas-generate-link', meetingData).then(({ link }) => link)
  }

  public setNylasTokenInEmissary (code: string) {
    return this.$axios.$post('/api/integrations/nylas-session-oauth-handshake', {
      code
    })
  }
}
